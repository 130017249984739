<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section class="text-center">
    <p>{{title}}</p>
    <p v-if="subtitle" class="font-weight-bolder">{{subtitle}}</p>
    <p v-if="note">{{note}}</p>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button variant="danger" @click="$emit('delete', true)">Delete</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
  export default {
    components: {},
    props: {
      title: {
        required: true,
        type: String,
      },
      subtitle: {
        required: false,
        type: String,
        default: '',
      },
      note: {
        required: false,
        type: String,
        default: '',
      },
    },
    data() {
      return {
        test: '',
      };
    },
    mounted() {

    },
    methods: {

    },
  };
</script>
