import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/kiosk-blacklist';

export default {
  getBlacklistUrls(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getBlacklistUrl(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  createBlacklistUrl(blacklistUrl) {
    return axios.post(`${baseUrl}`, blacklistUrl)
  },
  updateBlacklistUrl(id, blacklisturl) {
    return axios.put(`${baseUrl}/${id}`, blacklisturl)
  },
  deleteBlacklistUrl(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
}
