<style lang="scss">
</style>
<!--eslint-disable-->
<template>
  <div class="Kiosk-blacklist-page">
    <b-card class="" title="Kiosk Global Blacklist">
      <b-card-text>View, add and remove URLs currently in the global blacklist</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="row p-2 justify-content-start">

        <!-- Search -->
        <b-col class="col-md-6 col-12 mt-1">
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              @keyup.enter="getMoreBlacklistUrls(1)"
              class="d-inline-block" placeholder="Search by Blacklist URL..."
            />
            <b-input-group-append>
              <b-button @click="getMoreBlacklistUrls(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col class="col-md-6 col-12 mt-1">
          <b-input-group class="mb-1">
            <b-form-input
              class="d-inline-block" @keyup.enter="createBlacklistUrl" placeholder="Add Blacklist URL" v-model="blacklistUrl.url_keyword"
            />
            <b-input-group-append>
               <b-button variant="primary" @click="createBlacklistUrl" :disabled="blacklistUrl.url_keyword === ''">Add</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </div>

      <section>
        <b-table
          class="data-table"
          hover
          :items="blacklistUrls"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" @click="showDeleteModal(data.item, 'modal-delete-kiosk-blacklist-url')">
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreBlacklistUrls" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-kiosk-blacklist-url" title="Delete Blacklist URL" hide-footer>
      <delete-modal
        v-if="selectedBlacklistUrl"
        @close="closeModals()" @delete="deleteBlacklistUrl"
        :subtitle="blacklistUrl.name"
        title="Are you sure you want to remove this URL from the blacklist?"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeleteModal from '@/components/modals/DeleteModal.vue';
import KioskBlacklistService from '@/services/KioskBlacklistService';
import HelperService from '@/services/HelperService'

export default {
  name: 'KioskBlacklist',
  components: {
    DeleteModal,
  },
  props: {},
  data() {
    return {
      blacklistUrl: {
        url_keyword: '',
      },
      selectedBlacklistUrl: null,
      headers: [
        {
          key: 'url_keyword',
          label: 'Blacklist Keyword',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      blacklistUrls: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.setFilters();
    this.getBlacklistUrls();
  },
  methods: {
    refreshBlacklistUrls() {
      this.closeModals();
      this.getBlacklistUrls();
    },
    getMoreBlacklistUrls(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getBlacklistUrls();
    },
    setFilters() {
      this.filters.secure = this.$route.query.secure || ''
      this.filters.encrypted = this.$route.query.encrypted || ''
    },
    async getBlacklistUrls() {
      try {
        const res = await KioskBlacklistService.getBlacklistUrls(this.filters)
        this.blacklistUrls = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not get global kiosk blacklist URLS, please refresh and try again')
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreBlacklistUrls(1);
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-kiosk-blacklist-url');
    },
    showDeleteModal(blacklistUrl, modalName) {
      this.selectedBlacklistUrl = blacklistUrl;
      this.$bvModal.show(modalName);
    },
    async deleteBlacklistUrl() {
      try {
        await KioskBlacklistService.deleteBlacklistUrl(this.selectedBlacklistUrl.id)
        this.$toast.success(`Deleted ${this.selectedBlacklistUrl.url_keyword} successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.refreshBlacklistUrls();
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not delete entry, please refresh and try again')
      }
    },
    async createBlacklistUrl() {
      try {
        await KioskBlacklistService.createBlacklistUrl(this.blacklistUrl)

        this.$toast.success(`Added ${this.blacklistUrl.url_keyword} to global blacklist`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.blacklistUrl.url_keyword = ''
        this.refreshBlacklistUrls();
      } catch (err) {
        HelperService.showNotfyErr(this.$toast, err, 'Could not create entry, please refresh and try again')
      }
    },
  },
};
</script>
